<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
      <div class="breadcrumb">
      </div>
      <div class="row mb-4">
        <div class="col-sm-12 mb-4">
          <div class="card text-left">
            <div class="card-header" style="height:80px;">
              <div class="row" style="padding: inherit" v-if="!isMedico">
                <div class="col-sm-9">
                  <button
                    class="btn th-custom-color mr-4"
                    type="button"
                    @click="regresarMedico"
                    :disabled="this.user.tipo_user_id === 2 ? false : true"
                  >
                    Regresar
                  </button>
                  <img
                    v-if="medico && medico.logo_perfil"
                    style="border-radius: 120px; height: 50px;margin-right: 6px;"
                    :src="medico.logo_perfil"
                  >
                  <img
                    v-else
                    style="border-radius: 120px; height: 50px;margin-right: 6px;"
                    :src="require('@/assets/images/faces/img_default.png')"
                  >
                    <strong style="font-size: 15px;">
                      {{ medico.nombre }}
                    </strong>
                </div>
                <div class="col-sm-3 text-right">
                  <button
                    :class="showPatient ? 'btn-info' : 'btn-white'"
                    class="btn mr-2"
                    v-if="stateSelected === 3"
                    @click="getPacientesInConsultaExterna">Paciente</button>
                  <button
                    class="btn w-50 px-0"
                    :class="getColorButton"
                    @click="changeTypeButton"
                  >
                    {{getTextButton}}
                  </button>
                </div>
              </div>
              <div class="row" v-else>
                <div class="d-flex p-2 background-title">
                  <div class="font-weight-bold text-danger">
                      <p class="text-center">
                        El usuario no tiene vinculado un medico
                      </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row mb-1" v-if="stateSelected != 1">
                <div class="col-sm-3">
                  <div class="btn-group">
                    <span class="pt-2 mr-1">Mostrar </span>
                    <b-form-select
                      v-model="porPagina"
                      :options="paginaOpciones"
                    ></b-form-select>
                    <span class="pt-2 ml-1"> Registros</span>
                  </div>
                </div>
                <div class="col-sm-4 pacientesConsultaExternas">
                  <div class="row">
                    <div class="col-sm-3">
                      <button v-if="showPatient" @click="listarPacientes"
                          class="btn btn-white"
                          >Paciente</button>
                    </div>
                    <div class="col-sm-8">
                      <multiselect2
                        v-if="showPatient"
                        v-model="pacientesInConsultaExternaSelected"
                        track-by="id"
                        label="paciente"
                        placeholder="Seleccione un Paciente"
                        :options="pacientesInConsultaExternas"
                        :searchable="true"
                        @input="listarPacientes">
                      </multiselect2>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 offset-sm-2 text-right d-flex align-items-center" v-if="stateSelected !== 1">
                  <button v-if="showPatient"
                          class="btn btn-white"
                          >Medico</button>
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Buscar"
                    v-model="busqueda"
                    @keyup="filtrarBusqueda"
                  >
                </div>
              </div>
                <div class="row" v-if="stateSelected === 1">
                  <div class="col-sm-3 p-3"
                    v-for="(paciente, index) in getPacientesOrdered" :key="index"
                    @click="onClickEditarIcon(paciente)"
                    :title="paciente.cierre === 1 ? 'Consulta en cierre' : ''">
                    <div v-if="paciente.tipo_atencion !== null">
                        <div class="card" style="cursor: pointer;">
                          <div class="card-header border-radius-top" :class="getHeaderClass(paciente.tipo_atencion)">
                            <div class="row mb-2 ml-1">
                              <h4>
                                <strong>{{paciente.tipo_atencion}}</strong>
                              </h4>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-12" style="font-size:11px;">
                                  <h5><strong> {{ paciente.nombre }} </strong></h5>
                                </div>
                                <div class="col-sm-12" style="font-size:11px;">
                                  <h6><strong> {{ paciente.servicio }} </strong></h6>
                                </div>
                            </div>
                            <div class="row" style="margin-left: 25%;">
                                <div class="col-sm-8 text-right" style="font-size: 9px;">
                                    <span style="white-space: nowrap;">
                                      <strong>Origen: </strong>
                                      {{ paciente.origen }}
                                      <span :class="getConsultaColor(paciente.tipo_consulta)">
                                          <strong>({{ capitalizeFirstLetter(paciente.tipo_consulta) }})</strong>
                                      </span>
                                    </span>
                                    <br>
                                    <strong>Fecha: </strong>{{ paciente.fecha_origen }}
                                </div>
                                <div class="col-sm-4 text-right" style="font-size: 9px;">
                                  <span style="white-space: nowrap;">
                                    N° {{ paciente.consulta_numero }}
                                  </span>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card" style="cursor: pointer;">
                      <div class="card-header border-radius-top"
                        :class="paciente.headerClass">
                        <div class="row mb-2">
                          <div class="col-sm-5 text-left">
                            <div class="row">
                              <div class="col-sm-12">
                                <h4>
                                  <strong v-if="paciente.numero_ticket !== null">Turno {{paciente.numero_ticket}}</strong>
                                  <strong v-else>Abierto</strong>
                                </h4>
                                <h5 v-if="paciente.estado === 0"><strong>Anulado</strong></h5>
                              </div>
                              <div
                                class="offset-sm-1 col-sm-9 border border-success mb-1"
                                v-if="paciente.numero_turno === 1 || paciente.numero_turno === 2"
                              ></div>
                              <div
                                class="offset-sm-1 col-sm-9 border border-success"
                                v-if="paciente.numero_turno === 2"
                              ></div>
                            </div>
                          </div>
                          <div class="col-sm-7 text-right">
                            <div class="row">
                              <div class="col-sm-5 pr-0">
                                <strong>Hora:</strong>
                              </div>
                              <div class="col-sm-6 pl-1 text-left">
                                {{ timeFormatHHMM(paciente.hora) }}
                              </div>
                              <div class="col-sm-5 pr-0">
                                <strong> Fecha:</strong>
                              </div>
                              <div class="col-sm-7 pl-1 text-left">
                                {{ paciente.fecha }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="card-body">
                        <div class="row mb-2">
                          <div class="col-sm-12" style="font-size:11px;">
                            <h5><strong> {{ paciente.nombre }} </strong></h5>
                          </div>
                          <div class="col-sm-12" style="font-size:11px;">
                            <h6><strong> {{ paciente.servicio }} </strong></h6>
                          </div>
                        </div>
                        <div class="row" style="margin-left: 25%;">
                          <div class="col-sm-8 text-right" style="font-size: 9px;">
                            <span style="white-space: nowrap;">
                              <strong>Origen: </strong>
                              {{ paciente.origen }}
                              <span :class="getConsultaColor(paciente.tipo_consulta)">
                                  <strong>({{ capitalizeFirstLetter(paciente.tipo_consulta) }})</strong>
                              </span>
                            </span>
                            <br>
                            <strong>Fecha: </strong>{{ paciente.fecha_origen }}
                          </div>
                          <div class="col-sm-4 text-right" style="font-size: 9px;">
                            <span style="white-space: nowrap;">
                              N° {{ paciente.consulta_numero }}
                            </span>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="row" v-if="stateSelected !== 1">
                <div class="col-sm-12">
                  <TheTablePaciente
                  v-if="showPatient"
                  />
                  <b-table
                    v-else
                    class="table table-striped table-hover"
                    :fields="getFieldsTable"
                    :items="listaPacienteAtendidos"
                    :per-page="porPagina"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                        <strong>Cargando...</strong>
                      </div>
                    </template>
                    <template #cell(editar)>
                      <i
                        class="nav-icon i-Pen-5 font-weight-bold"
                        :class="hasPermisionModificarConsultaExterna? 'text-success':''"
                        style="cursor: pointer"
                      ></i>
                    </template>
                    <template #cell(print)='items'>
                      <i
                        class="nav-icon i-File-Download font-weight-bold"
                        :class="hasPermisionImprimirConsultaExterna? 'text-info':''"
                        style="cursor: pointer"
                        @click="openModalPrint(items.item)"
                      >
                      </i>
                    </template>
                    <template #cell(fecha_registro)="row">
                      {{ formatDate(row.item.fecha_registro) }} <span style="color:blue"> {{ getTime(row.item.fecha_registro) }}</span>
                    </template>
                    <template #cell(fecha_reserva)="row">
                      {{ formatDate(row.item.fecha_reserva) }} <span style="color:blue"> {{ getTime(row.item.fecha_reserva) }}</span>
                      <span
                        v-if="row.item.venta_modificada"
                        class="text-danger font-weight-bold"
                      >{{ row.item.venta_modificada }}</span>
                    </template>
                    <template #cell(consulta)="row">
                      {{ formatDate(row.item.consulta) }} <span style="color:blue">{{ getTime(row.item.consulta) }}</span>
                      <span
                        class="text-success font-weight-bold"
                      >
                        {{row.item.turno_medico}}
                      </span>
                    </template>
                    <template #cell(seguro)="row">
                      <i
                        v-if="row.item.seguro"
                        class="text-20 nav-icon i-ID-Card"
                        style="cursor: pointer"
                      ></i>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8"></div>
                <div class="col-sm-4">
                  <b-pagination
                    v-if="stateSelected != 1"
                    v-model="paginaActual"
                    :total-rows="totalFilas"
                    :per-page="porPagina"
                    @input="listarPacientes"
                    class="my-0 float-right"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <modalPrint :itemService="itemServicio" :selectedConsulta="selectedId"/>
  </div>
</template>

<script>

import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapActions, mapState } from 'vuex';
import Multiselect2 from '@/components/util/vue-multiselect2';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import moment from 'moment';
import modalPrint from '../components/modalPrint.vue';
import TheTablePaciente from '../components/TheTablePaciente.vue';

export default {
  name: 'pacienteMedico',
  components: {
    modalPrint,
    TheTablePaciente,
    Multiselect2,
  },
  data() {
    return {
      isLoading: true,
      isMedico: false,
      selectedId: null,
      selectedNro: null,
      tipoMedico: null,
      itemServicio: [],
      fields: [
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'print', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'N° C.E', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '6%' },
        },
        {
          key: 'vc', label: 'VC', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'fecha_registro', label: 'F/Registro', tdClass: 'text-12 px-0', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '13%' },
        },
        {
          key: 'fecha_reserva', label: 'F/Reserva', tdClass: 'text-12 px-0', thClass: 'th-custom-color', class: 'text-center px-0', thStyle: { width: '13%' },
        },
        {
          key: 'paciente', label: 'Paciente', thClass: 'th-custom-color', class: 'text-center'/* , thStyle: { width: '17%' } */,
        },
        {
          key: 'consulta', label: 'Consulta', tdClass: 'text-12 px-0', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '13%' },
        },
        {
          key: 'reconsulta', label: 'Re Consulta', tdClass: 'text-12 px-0', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '13%' },
        },
        {
          key: 'seguro', label: 'Seguro', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '7%' },
        },
        {
          key: 'pago', label: 'Pago', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '7%' },
        },
      ],
      showPatient: false,
      delayTimeout: null,
    };
  },
  async created() {
    // SUPER USUARIO
    await this.setStateSelected();
    await this.listarPacientes();
  },
  computed: {
    getColorButton() {
      let classColor = '';
      switch (this.stateSelected) {
        case 1:
          classColor = 'btn-success';
          break;
        case 2:
          classColor = 'btn-light';
          break;
        case 3:
          classColor = this.showPatient ? 'btn-white' : 'btn-info';
          break;

        default:
          break;
      }
      return classColor;
    },
    getFieldsTable() {
      let customFields = this.fields;
      if (this.stateSelected === 2) {
        customFields = customFields.filter((obj) => (
          obj.key !== 'pago'
        ));
        customFields = customFields.filter((obj) => (
          obj.key !== 'seguro'
        ));
      }
      return customFields;
    },
    getTextButton() {
      let text = '';
      switch (this.stateSelected) {
        case 1:
          text = 'Para Hoy';
          break;
        case 2:
          text = 'Atendidos Hoy';
          break;
        case 3:
          text = 'Resueltos (todos)';
          break;

        default:
          break;
      }
      return text;
    },
    ...mapState('main', [
      'user',
    ]),
    getPacientesOrdered() {
      const arrayTemp = this.pacientes;
      return arrayTemp.sort((a, b) => (a.numero_ticket - b.numero_ticket));
    },
    ...mapGetters('main', [
      'getFuncionalidades',
      'isBloqueadoReConsulta',
      'hasPermisionListarConsultaExterna',
      'hasPermisionModificarConsultaExterna',
      'hasPermisionImprimirConsultaExterna',
    ]),
    ...mapFields('ConsultaExternaPaciente', [
      'stateSelected',
      'pacientes',
      'medico',
      'paginaActual',
      'totalFilas',
      'porPagina',
      'paginaOpciones',
      'listaPacienteAtendidos',
      'pacientesInConsultaExternaSelected',
      'pacientesInConsultaExternas',
      'busqueda',
    ]),
  },
  methods: {
    getHeaderClass(tipoAtencion) {
      return {
        'bg-warning': tipoAtencion === 'Medicina General',
        'bg-danger': tipoAtencion === 'Emergencia',
      };
    },
    timeFormatHHMM(hora) {
      return moment(hora, 'HH:mm:ss').format('HH:mm');
    },
    changeTypeButton() {
      this.showPatient = false;
      this.pacientesInConsultaExternaSelected = null;
      if (this.stateSelected === 3) {
        this.stateSelected = 1;
      } else {
        this.stateSelected += 1;
      }
      this.listarPacientes();
    },
    // eslint-disable-next-line camelcase
    getConsultaColor(tipo_consulta) {
      // eslint-disable-next-line camelcase
      if (tipo_consulta === 'consulta') {
        return 'text-success'; // Verde
      // eslint-disable-next-line camelcase
      } if (tipo_consulta === 'reconsulta') {
        return 'text-warning'; // Anaranjado
      }
      return '';
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setStateSelected() {
      const STATE_SELECTED = this.$route.params.stateSelected
        ? atob(this.$route.params.stateSelected) : 1;
      this.stateSelected = parseInt(STATE_SELECTED, 10);
    },
    async listarPacientes() {
      const MEDICO_ID = atob(this.$route.params.id);
      this.tipoMedico = this.$route.params.tipo_turno_medico_id;
      if (MEDICO_ID !== 'null') {
        if (this.stateSelected === 2
          || this.stateSelected === 3
        ) {
          const PACIENTE_ID = this.pacientesInConsultaExternaSelected
            ? this.pacientesInConsultaExternaSelected.id : 0;

          const DTO = {
            id: MEDICO_ID,
            state_selected: this.showPatient ? 4 : 3,
            paciente_id: PACIENTE_ID,
          };
          await this.getListarAtencionPaciente(DTO);
        } else {
          await this.getDatoinicial(MEDICO_ID);
        }
      } else {
        this.isMedico = true;
      }
      this.isLoading = false;
    },
    encrypt(id) {
      return btoa(id);
    },
    regresarMedico() {
      this.$router.push({ name: 'consulta-externa-index' });
    },
    ...mapActions('ConsultaExternaPaciente', [
      'resetStatePaciente',
      'getDatoinicial',
      'getListarAtencionPaciente',
    ]),
    onClickEditarIcon(item) {
      if (!item.cierre) {
        localStorage.setItem('Paciente-Consulta-Externa', JSON.stringify(item));
        this.$router.push({ name: 'consulta-externa-edit', params: { id: btoa(item.consulta_id) } });
      }
    },
    openModalPrint(item) {
      if (this.hasPermisionImprimirConsultaExterna) {
        this.selectedId = item;
        // eslint-disable-next-line no-unused-vars
        this.getServicioComplementario(item.consulta_id).then((e) => {
          this.$bvModal.show('modalPrintConsultaExterna');
        });
      }
    },
    async getServicioComplementario(item) {
      try {
        const RESPONSE = await axios.get(`clinic/consulta_externa/${item}/get_categoria_servicio_complementarios`);
        this.itemServicio = RESPONSE.data.data.categoria_servicio_complementarios;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    updateSelectedId(value) {
      this.selectedId = value;
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const match = dateString.match(/(\d{2}\/\d{2}\/\d{4})/);
      return match ? match[0] : '';
    },
    getTime(dateString) {
      if (!dateString) return '';
      const match = dateString.match(/(\d{2}:\d{2})/);
      return match ? match[0] : '';
    },
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarPacientes, DELAY);
    },
    getPacientesInConsultaExterna() {
      this.showPatient = !this.showPatient;
      this.pacientesInConsultaExternaSelected = null;
    },
  },
  watch: {
    porPagina() {
      this.listarPacientes();
    },
    stateSelected(new1) {
      if (new1 === 3) {
        // limpiar la tabla
        this.listaPacienteAtendidos = [];
      }
    },
  },
  beforeDestroy() {
    this.resetStatePaciente();
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: rgb(237, 237, 237);
}
.border-radius {
  border-radius: 10px;
}
.border-radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.btn-white{
  background: white;
  color: black;
}
.pacientesConsultaExternas {
  margin: auto;
  margin-right: -15%;
}
</style>
